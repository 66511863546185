<template>
	<div class="rintroduce">
		<div class="rintroduce-nav">
			<div class="scroll">
				<ul class="rintroduce-nav-content" @click="handleClick">

					<li :index="0">评估标准智能化</li>
					<li :index="1">入住流程电子化</li>
					<li :index="2">服务质量监管</li>
					<li :index="3">异常状况智能报警</li>
					<li :index="4">结算费用自动化</li>
				</ul>
			</div>

		</div>
		<div v-if="activeIndex == 0" class="view1">
			<img class="img1" src="../../assets/global/residential/ys-img1.png" />
			<div class="content1">
				<div class="content1_title">
					<img class="jiantou1" src="../../assets/global/jiantou0.png" />
					<div class="title">老人入院评估(初评)</div>
					<img class="jiantou2" src="../../assets/global/jiantou0.png" />
				</div>
				<div class="content">
					按照评估标准规范，系统设置有 12 套现成评估表，评估员根据系统评估表标准对老人表现出来的现状进行评估勾选，系统自动化计算，杜绝人为计算错误，得出老人当前的评估分数和评估结论。
				</div>
			</div>
			<img class="img2" src="../../assets/global/residential/ys-img2.png" />
			<div class="content1">
				<div class="content1_title">
					<img class="jiantou1" src="../../assets/global/jiantou0.png" />
					<div class="title">老人周期评估(复评)</div>
					<img class="jiantou2" src="../../assets/global/jiantou1.png" />
				</div>
				<div class="content">
					在院老人需要周期性进行身体状况评估，系统根据周期自动提醒评估员，避免评估员忘记复评；以评估数据结论为依据，对老人进行合理的服务调整。 </div>
			</div>
			<img class="img2" src="../../assets/global/residential/ys-img3.png" />
			<div class="content1">
				<div class="content1_title">
					<img class="jiantou1" src="../../assets/global/jiantou0.png" />
					<div class="title">机构星级评定</div>
					<img class="jiantou2" src="../../assets/global/jiantou1.png" />
				</div>
				<div class="content">
					评估系统依照省星级评定标准进行设计研发，通过智能平板协助星评评估专家进行智能化评定；为评估数据查证提供有力的支撑。 </div>
			</div>
		</div>
		<div v-if="activeIndex == 1" class="view1">
			<img class="img1" src="../../assets/global/residential/ys-img4.png" />
			<div class="content1">
				<div class="content1_title">
					<img class="jiantou1" src="../../assets/global/jiantou0.png" />
					<div class="title">无纸化入住</div>
					<img class="jiantou2" src="../../assets/global/jiantou1.png" />
				</div>
				<div class="content">
					系统一键办理入住，信息电子化，记录查询高效快捷；优化传统纸质信息存档，节省办公用品成本。
				</div>
			</div>
			<img class="img2" src="../../assets/global/residential/ys-img5.png" />
			<div class="content1">
				<div class="content1_title">
					<img class="jiantou1" src="../../assets/global/jiantou0.png" />
					<div class="title">电子签名</div>
					<img class="jiantou2" src="../../assets/global/jiantou1.png" />
				</div>
				<div class="content">
					入住合同、入住须知，入住评估，入住家属同意书等文件老人家属通过电子化签名确认，便捷、永久保存，随时可导出查证。
				</div>
			</div>
			<img class="img2" src="../../assets/global/residential/ys-img6.png" />
			<div class="content1">
				<div class="content1_title">
					<img class="jiantou1" src="../../assets/global/jiantou0.png" />
					<div class="title">老人档案</div>
					<img class="jiantou2" src="../../assets/global/jiantou1.png" />
				</div>
				<div class="content">
					老人基本资料、家属资料、病史资料、院内事项完全信息化留档，数据一键记录和查询，高效便捷，永久保存，避免信息档案遗失或因存档时间长久腐烂等情况，建立老人档案动态数据库。
				</div>
			</div>
		</div>
		<div v-if="activeIndex == 2" class="view1">
			<img class="img1" src="../../assets/global/residential/ys-img7.png" />
			<div class="content1">
				<div class="content1_title">
					<img class="jiantou1" src="../../assets/global/jiantou0.png" />
					<div class="title">事故处理及跟进监管</div>
					<img class="jiantou2" src="../../assets/global/jiantou1.png" />
				</div>
				<div class="content">
					系统对事故细节全面记录，事故信息实时连接护士站显示屏进行提醒跟进，完全实现电子化，提高事故处理监管，工作人员责任落实到位。
				</div>
			</div>
			<img class="img2" src="../../assets/global/residential/ys-img8.png" />
			<div class="content1">
				<div class="content1_title">
					<img class="jiantou1" src="../../assets/global/jiantou0.png"/>
					<div class="title">日常照护监管</div>
					<img class="jiantou2" src="../../assets/global/jiantou1.png" />
				</div>
				<div class="content">
					老人日常照护项目可在系统一键开启，照护信息数据实时连接护士站显示屏进行提醒跟进；
					并通过老人房前人脸识别系统与智慧院舍系统数据连接，实时查看监管护士/护工的巡房看护记录，对老人突发状况起着重大保障作用。 </div>
			</div>
			<img class="img2" src="../../assets/global/residential/ys-img9.png" />
			<div class="content1">
				<div class="content1_title">
					<img class="jiantou1" src="../../assets/global/jiantou0.png" />
					<div class="title">护理警报处理监管</div>
					<img class="jiantou2" src="../../assets/global/jiantou1.png" />
				</div>
				<div class="content">
					系统通过引入物联网硬件设备，针对不同身体素质的老人使用一定的健康安全监测设备，设备数据达到异常值时，通过系统、员工手表等警报通知护士/护工跟进处理；通过系统、设备、
					物联网等结合对服务进行全面监管，全面保障老人安全健康。
				</div>
			</div>
		</div>
		<div v-if="activeIndex == 3" class="view1">
			<img class="img1" src="../../assets/global/residential/ys-img10.png" />
			<div class="content1">
				<div class="content1_title">
					<img class="jiantou1" src="../../assets/global/jiantou0.png" />
					<div class="title">毫秒级延迟</div>
					<img class="jiantou2" src="../../assets/global/jiantou1.png" />
				</div>
				<div class="content">
				通过物联网硬件设备 (智能纸尿裤、血糖仪、血压仪等) 监测，数据实时上传系统，达到异常值智能触发警报，实现毫秒级延迟速度。				</div>
				</div>
			<img class="img2" src="../../assets/global/residential/ys-img11.png" />
			<div class="content1">
				<div class="content1_title">
					<img class="jiantou1" src="../../assets/global/jiantou0.png" />
					<div class="title">无感接触，智能感知触发警报</div>
					<img class="jiantou2" src="../../assets/global/jiantou1.png" />
				</div>
				<div class="content">
					物联网硬件设备通过无感接触，自动化上传数据，根据系统设置的警报红线值，自动触发本地警报、声音警报
					远程警报等。
					</div>
			</div>
			<img class="img2" src="../../assets/global/residential/ys-img12.png" />
			<div class="content1">
				<div class="content1_title">
					<img class="jiantou1" src="../../assets/global/jiantou0.png" />
					<div class="title">多样化智能设备警报</div>
					<img class="jiantou2" src="../../assets/global/jiantou1.png" />
				</div>
				<div class="content">
					系统通过引入物联网硬件设备，针对不同身体素质的院舍老人使用一定的健康安全监测设备，针对不同设备警报做出应急处理。
				</div>
			</div>
		</div>
		<div v-if="activeIndex == 4" class="view1">
			<img class="img1" src="../../assets/global/residential/ys-img13.png" />
			<div class="content1">
				<div class="content1_title">
					<img class="jiantou1" src="../../assets/global/jiantou0.png" />
					<div class="title">全自动生成结算单</div>
					<img class="jiantou2" src="../../assets/global/jiantou1.png" />
				</div>
				<div class="content">
每月根据日常护理服务、物资、水电等费用的信息化记录，系统自动生成费用结算单，优化了传统人工结算，提高工作效率。
				</div>
			<img class="img2" src="../../assets/global/residential/ys-img14.png" />
			<div class="content1">
				<div class="content1_title">
					<img class="jiantou1" src="../../assets/global/jiantou0.png" />
					<div class="title">避免费用少收漏收</div>
					<img class="jiantou2" src="../../assets/global/jiantou1.png" />
				</div>
				<div class="content">
					系统自动根据服务费、物资费、减免费计算，得出结算总费用，实现全自动化，避免人工计算误差导致的少收漏收。
					</div>
			</div>
			<img class="img2" src="../../assets/global/residential/ys-img15.png" />
			<div class="content1">
				<div class="content1_title">
					<img class="jiantou1" src="../../assets/global/jiantou0.png" />
					<div class="title">客户自助缴费</div>
					<img class="jiantou2" src="../../assets/global/jiantou1.png" />
				</div>
				<div class="content">
					链接一键发送费用清单，家属对费用清单一目了然，在线扫码缴费，足不出门，方便快捷。
				</div>
			</div>
		</div>
	</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				activeIndex: 0,
			};
		},
		mounted() {
			let el = document.querySelector(".rintroduce-nav-content");
			el.getElementsByTagName("li")[0].className = 'active';
		},

		methods: {
			handleClick(e) {
				console.log('点击--->', e.target.getAttribute('index'));
				if (e.target.getAttribute("index")) {
					this.activeIndex = e.target.getAttribute("index");
				}

				console.log('e--->', e);
				let el = document.querySelector(".rintroduce-nav-content");
				let elList = el.getElementsByTagName("li");


				if (e.target.getAttribute("index")) {
					for (var i of elList) {
						i.className = '';
					}
					e.target.className = 'active';
				}

			},
		},
	}
</script>

<style lang="scss" scoped>
	.rintroduce {
		display: flex;
		flex-direction: column;

		.rintroduce-nav {
			.scroll {
				overflow: auto;
				margin-top: .24rem;

				.rintroduce-nav-content {
					display: flex;
					width: 6.15rem;
					padding: 0 .16rem;

					li {
						font-size: .13rem;
						color: rgba(0, 0, 0, 0.6);
						height: .28rem;
						line-height: .28rem;
						background-color: rgba(0, 0, 0, 0.06);
						border-radius: .14rem;
						padding: 0 .08rem;
						margin-right: .12rem;

						&:last-child {
							margin-right: 0;
						}
					}

					.active {
						background-color: #00ACF6;
						color: #FFFFFF;
					}
				}
			}
		}

		.view1 {
			display: flex;
			flex-direction: column;
			margin-top: 26px;
			padding-bottom: 48px;

			.img1 {
				// margin-top: 52rpx;
				width: 100%;
			}

			.img2 {
				margin-top: 50px;
				width: 100%;
			}

			.content1 {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 0 16px;

				.content1_title {
					width: 100%;
					display: flex;
					flex-direction: row;
					align-items: center;

					// background-color: #212121;
					.jiantou1 {
						margin-left: auto;
					}

					.title {
						margin-left: 6px;
						margin-right: 6px;
						font-weight: bold;
						font-size: 17px;
					}

					.jiantou2 {
						margin-right: auto;
					}
				}

				.content {
					line-height: 180%;
					margin-top: 8px;
					// height: 94px;
					text-align: justify;
					font-size: 13px;
					color: rgba(0, 0, 0, 0.6);
				}
			}
		}

	}
</style>
